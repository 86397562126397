import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexCellini = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexCellini {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Cellini/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Cellini Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-cellini/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Cellini Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-cellini/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-cellini-header.png"
                alt="Pre-Owned Certified Used Rolex Cellini Watches Header"
                aria-label="Used Rolex Cellini Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX CELLINI: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX CELLINI WATCHES</h2>
            <p>
              These days people say, “I want to sell my Rolex Cellini watch for cash.”
              <a href="/">www.grayandsons.com </a> and{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              will buy your watch for cash. Gray and Sons is the #1 place to repair Rolex Cellini
              watches, buy used Rolex Cellini watches, and sell Rolex Cellini watches. At Gray and
              Sons, we buy, sell, trade and repair used and pre owned Rolex Cellini watches.
              <br />
              <br />
              Celebrating all that is elegance and class, the Rolex Cellini collection puts a modern
              twist to the traditional timepiece. The name Cellini is derived from Italian
              goldsmith, Benvenuto Cellini. Prior to this collection, Rolex was known for
              specializing in durable and trustworthy tool watches. That was until the master-mind
              Rene-Paul Jeanneret suggested Rolex open up a new branch of watches meant for a
              dressier setting. Thanks to their timeless elegance and exquisite craftsmanship, a
              Rolex Cellini, whether new or used, is one of the finest timepieces to own and you can
              get one today at Gray and Sons Jewelers in Surfside, Miami Beach, Florida. Reach out
              to Gray and Sons with your questions and make a deal today with a live representative
              who is ready to make a deal with you. All of our Rolex watches come freshly serviced,
              like-new, and with a 24-month warranty.
              <br />
              <br />
              For over 50 years, the Cellini remains a mainstay of the Rolex Model Collections. With
              its clean lines, perfectly symmetrical round case, straight lugs, and detailed dials,
              the Rolex Cellini is a classic dress watch that took style after a more traditional
              timepiece. They are generally crafted from precious metals, such as gold or platinum,
              and typically come fitted with leather straps. While Rolex has created some Cellini
              models with more intricate complications, the vast majority of Cellini watches are
              either time-only or include a sub-dial of some sort. There are several benefits to
              buying pre-owned Rolex Cellini watches, including saving money, sourcing a
              discontinued model, or finding a rare vintage example. If you’re shopping for a second
              hand Rolex Cellini, here are a few things to keep in mind: 1. There are many styles of
              Cellini models (square, round, tonneau, etc.). 2. Cellini watches can come in all
              precious metals(18k yellow, 18k white, Everose, and Platinum). 3. The case sizes of
              Cellini watches can vary from as small as 18mm to over 35mm. 4. Depending on when it
              was made, Cellini watches can be found with manual movements, automatic movements, and
              quartz movements.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Cellini Watches</b>
              <br />
              Popular Pre-Owned Rolex Cellini References are: Reference 6693, Reference 6692,
              Reference 6673, Reference 6628, Reference 6623, Reference 6621, Reference 6110,
              Reference 5330, Reference 5241, Reference 5116, Reference 4243, Reference 4233,
              Reference 4223, Reference 4113, Reference 4112. Rolex makes some of the finest watches
              in the market, complete with intricate mechanical movements. As such, you should only
              trust specialized watchmakers to work on your pre-owned Rolex Cellini. At Gray and
              Sons you will find ROLEX CELLINI: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED
              ROLEX CELLINI WATCHES. For buying pre-owned Rolex Cellini watches, visit us at{' '}
              <a href="/">www.grayandsons.com </a> If you are looking to sell your luxury watch,
              visit <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              and you will get the best price offer. Gray and Sons is the place to call if you ever
              question, "Where should I sell my watch?" Talk to a real person on our website who can
              help you buy, sell, trade, repair used and pre owned watches.{' '}
              <a href="/chat-with-rich">CHAT NOW </a> with Viktoria or Rich? Vika and Rich are both
              real specialists ready to make a deal now.
              <br />
              <br />
              <b>Why Gray &amp; Sons is The Best Source for Buying and Selling Rolex</b>
              <br />
              The Gray &amp; Sons watch service facility is home to a team of skilled watchmakers
              who are highly knowledgeable in high-end Swiss timepieces. Our master watchmakers are
              experts in s ervicing and repairing second hand Rolex Cellini watches. We offer
              battery replacement for Rolex watches. We are a real watch and jewelry store located
              in Surfside, Miami across from Bal Harbour Shops, with real watch and jewelry experts
              on-site, open six days a week, we are not a virtual shop. Our customers say we are #1
              Miami used watch buyer and seller proudly assisting for the past 42 years sellers and
              buyers of pre-owned Rolex Cellini watches in Miami, Miami Beach, Coral Gables, Coconut
              Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Aventura, Fort Lauderdale and
              many more areas. We offer certified pre-owned watches.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex/rolex-cellini/">
                <button>SHOP ROLEX CELLINI WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX CELLINI WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexCellini
